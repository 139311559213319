import * as Yup from 'yup';

const SymptomValidationSchemaconst = Yup.object().shape({
    
    symptomtitle: Yup.string().required('Symptom title is required!').max(100, "Maxlenght 100 character."),  
    shortDesc: Yup.string().required('Short Description is required!').max(500,"Maxlenght 500 character.")    
});




export default SymptomValidationSchemaconst;