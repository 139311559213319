import * as actions from '../actionTypes';

 const loggedInUser = (payload) => ({
    type: actions.LOGGED_IN_USER,
    payload: {
        payload
    }
});

const loggedOut = () => ({
    type: actions.LOGGED_OUT,
    payload: undefined
});


export {
    loggedInUser,
    loggedOut
}