import React, { useEffect } from "react";
import {
    
    Outlet
    
  } from 'react-router-dom';
import Header from "../Component/Layouts/header";
import SideBar from "../Component/Layouts/sideBar";

import layOutInitilization from  '../Component/Layouts/vartical-layout'



const MainLayout=()=>{
   useEffect(()=>{
    layOutInitilization();
   },[]);
    return (
       
            <div id="pcoded" className="pcoded">
                <div className="pcoded-overlay-box" />
                <div className="pcoded-container navbar-wrapper">
                    <Header />
                    <div className="pcoded-main-container">
                        <div className="pcoded-wrapper">
                            <SideBar />
                            <div className="pcoded-content">
                                <div className="pcoded-inner-content">
                                    <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="page-body">
                                            <Outlet />
                                        </div>
                                    </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        
    )
}

export default MainLayout;