import React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import TagsValidationSchemaconst from './tagValidations';
import tagBusiness from './tagsBusiness';



const Tags = () => {

    const navigate = useNavigate();
    const location = useLocation();

   

    let initialValues = {
        tagId: location.state ? location.state.tagId : 0,
        name: location.state ? location.state.name : "",
        description: location.state ? location.state.description : "",
        showSuccess: false,
        showError: false

    }

    return (
        <React.Fragment>

            <Formik
                initialValues={initialValues}

                validationSchema={TagsValidationSchemaconst}

                onSubmit={async (values, onSubmitProps) => {
                    let tagBusinessObj = new tagBusiness();

                    let param = {
                        "tagId": values.tagId,
                        "name": values.name,
                        "description": values.description,                      
                        "isActive": 1,
                        "loginUserId": 0
                    }

                    let response = await tagBusinessObj.saveTag(param);
                   

                    if (response && response.success) {
                        onSubmitProps.setSubmitting(false);
                        onSubmitProps.setFieldValue("description", "");
                        onSubmitProps.setFieldValue("name", "");
                        //onSubmitProps.resetForm();

                       

                        onSubmitProps.setFieldValue("showSuccess", true);

                        setTimeout(() => {
                            onSubmitProps.setFieldValue("showSuccess", false);
                        }, 3000);
                    } else {

                    }


                }}
            >
                {
                    (formik) => {
                        return (

                            <Form className="md-float-material form-material">
                                <div className="page-header">
                                    <div className="row align-items-end">
                                        <div className="col-lg-6">
                                            <div className="page-header-title">
                                                <div className="d-inline">
                                                    <h4>Add/Update Tags</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <div className="float-right">
                                                <button type="button" className="btn btn-inverse m-r-10" onClick={() => { navigate("/tagList") }} ><i className="icofont icofont-arrow-left m-r-5" />Back to Tags List</button>
                                                <button type="reset" className="btn btn-info m-r-10" ><i className="icofont icofont-close m-r-5" />Cancel</button>
                                                <button type="submit"
                                                    disabled={formik.isSubmitting}
                                                    className="btn btn-primary">

                                                    {formik.isSubmitting && <React.Fragment> <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>   Please wait... </React.Fragment>}
                                                    {!formik.isSubmitting && <React.Fragment><i className="icofont icofont-save"></i> Save</React.Fragment>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">

                                                <div className="card-block">
                                                {
                                                    formik.values.showSuccess &&
                                                    <div className="alert alert-dismissible alert-success">
                                                        
                                                        <strong>Tag Saved successfully!</strong>
                                                    </div>

                                                }
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="col-form-label">
                                                                    Name <span className="text-danger">*</span>
                                                                </label>
                                                                <input autoComplete="off" type="text" className={'form-control' + (formik.errors.name && formik.touched.description ? ' is-invalid' : '')}
                                                                    name="name" 
                                                                    value={formik.values.name}     
                                                                    maxLength={100}                                                             
                                                                    onChange={e => {
                                                                        formik.setFieldValue("name", e.target.value);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="col-form-label">
                                                                    Description
                                                                </label>
                                                                <textarea className={'form-control' + (formik.errors.description && formik.touched.description ? ' is-invalid' : '')}
                                                                    name="description"
                                                                    maxLength={500}
                                                                    rows={5}
                                                                    value={formik.values.description}     
                                                                    onChange={e => {
                                                                        formik.setFieldValue("description", e.target.value);

                                                                    }} />
                                                                <ErrorMessage name="description" component="div" className="invalid-feedback" />



                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </Form>




                        )
                    }
                }
            </Formik>



        </React.Fragment>
    )

}


export default Tags;