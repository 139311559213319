import * as Yup from 'yup';

const LoginValidationSchemaconst  = Yup.object().shape({
    password: Yup.string()       
        .required('Password is required!'),
        loginname: Yup.string().required('User name is required!'),
});




export default LoginValidationSchemaconst;