import { combineReducers } from 'redux';
import * as actions from '../Redux/actionTypes';

import loginReducer from './Reducers/reducer';

const appReducer = combineReducers({
     loginReducer
});

const rootReducer = (state, action) => {
    
     if (action.type === actions.LOGGED_OUT) {
       state = undefined;
     }
   
     return appReducer(state, action);
   };

export default rootReducer;