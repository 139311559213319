import React, { useEffect, useState, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useLocation, useNavigate } from 'react-router-dom';

import symptomBusiness from "./symptomBusiness";


const ViewSymptom = () => {
    
    const location = useLocation();
    const navigate = useNavigate();

    const [symptomData, getSymptom] = useState({});

    const fnGetSymptom = useCallback(async () => {
        let sysBusinessObj = new symptomBusiness()

        let data = await sysBusinessObj.getSymptom(location.state.symptomId);
        getSymptom(data);
    }, []);

    useEffect(() => {
       
        fnGetSymptom() 
        
    }, [fnGetSymptom]);


    if (!symptomData.hasOwnProperty('symptomId')) {
        return (
            <React.Fragment>
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="page-header-title">
                                <div className="d-inline">
                                    <h4>View Symptom Details</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page-body'>
                    <div className='row'>
                        <div className='col-lg-12 col-xl-12'>
                            <div className='card'>
                                <div className='card-block'>
                                    <div className="preloader3 loader-block">
                                        <div className="circ1" />
                                        <div className="circ2" />
                                        <div className="circ3" />
                                        <div className="circ4" />
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }


    return (
   
        <React.Fragment>
           
            <div className="page-header">
                <div className="row align-items-end">
                    <div className="col-lg-7">
                        <div className="page-header-title">
                            <div className="d-inline">
                                <h4>View Symptom Details</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="float-right">
                            <button type="button" className="btn btn-inverse" onClick={() => { navigate("/symptomList") }} ><i className="icofont icofont-arrow-left m-r-5" />Back to Symptom List</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-body">
                <div className='row'>
                    <div className='col-lg-12 col-xl-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <h4>Short Description</h4>
                                <span className='text-muted m-b-0'>
                                    {symptomData.shortDescription}
                                </span>
                            </div>
                            <div className='card-block'>
                                <h4 className='sub-title'>Detail Description</h4>
                                {ReactHtmlParser(symptomData.detailDescription)}
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-12 col-xl-4'>
                        <div className="card">
                            <div className="card-header">
                                <h5>Anatomy</h5>
                            </div>
                            <div className="card-block">
                                {
                                    symptomData.anatomies && symptomData.anatomies.map(anatomy =>

                                        <label class="form-label label label-inverse-info">
                                            {anatomy.name}
                                            <span class="m-l-5">X</span>
                                        </label>
                                        
                                    )
                                }

                            </div>
                        </div>

                        <div className="card job-right-header">
                            <div className="card-header">
                                <h5>Tags</h5>
                            </div>
                            <div className="card-block">
                                {
                                    symptomData.tags && symptomData.tags.map(tag =>
                                        <label class="form-label label label-inverse-primary">
                                            {tag.name}
                                            <span class="m-l-5">X</span>
                                        </label>
                                    )
                                }

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </React.Fragment>


    )

}


export default ViewSymptom