import { deleteAPICall,postAPICall } from '../../../utils/apiCallFunctions';
import {  tags } from '../../../utils/apiURLs';


class tagsBusiness {
    saveTag = async (param) => {
        const response = await postAPICall(tags.savetag, param);
        return response;

    } 

    deleteTag = async (id) => {
        const response = await deleteAPICall(tags.deleteTag.replace("{0}", id));
        return response;

    } 
}


export default tagsBusiness;