import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import symptomBusiness from "./symptomBusiness";

import DataTable from '../../../Component/Datatable/Datatable'
// import ViewSymptom from './viewSymptom';


const SymptomList = () => {
    const navigate = useNavigate();

    const [anatomyList, getAnatomy] = useState([]);
    const [tagsList, getTags] = useState([]);
    const [symptomList, getSymptom] = useState([]);
    const [title, setTitle] = useState("");
    const [anatomy, setAnatomy] = useState([]);
    const [tags, setTags] = useState([]);
    

    const fnAnatomyListCall = async () => {
        let sysBusinessObj = new symptomBusiness()
        let param = {
            "key": "",
            "name": ""
        }
        let list = await sysBusinessObj.getAnatomyList(param);
        getAnatomy(list);
    }

    const fnTagListCall = async () => {
        let sysBusinessObj = new symptomBusiness()
        let param = {
            "name": ""
        }
        let list = await sysBusinessObj.getTagList(param);

        getTags(list);
    }

    const fnSearchSymptomCall = async () => {
        let sysBusinessObj = new symptomBusiness()
        let param = {
            "symptomId": 0,
            "isActive": 1,
            "loginUserId": 0,
            "anatomies": anatomy.join(","),
            "tags": tags.join(','),
            "title": title
        }
        let list = await sysBusinessObj.getSymptomList(param);

        getSymptom(list);
    }

    const fnGetAnatomyList = useCallback(fnAnatomyListCall, []);

    const fnTagList = useCallback(fnTagListCall, []);

    const searchSymptom = useCallback(fnSearchSymptomCall,[]);


    useEffect(() => {
        window.$('.js-example-basic-multiple').select2();

        window.$('#anatomySelect').on('select2:select', function (e) {
            setAnatomy(selectedAnatomy=>[...selectedAnatomy, e.params.data.id]);
        });

        window.$('#tagSelect').on('select2:select', function (e) {                          
            setTags(selectedTags=>[...selectedTags, e.params.data.id])
        });

        window.$('#anatomySelect').on('select2:unselect', function (e) {
            setAnatomy((selectedAnatomy) => selectedAnatomy.filter(x=>x!==e.params.data.id ));
        });

        window.$('#tagSelect').on('select2:unselect', function (e) {            
            setTags((selectedTags) => selectedTags.filter(x=>x!==e.params.data.id ))
        });

    }, [])

    useEffect(() => {
        fnGetAnatomyList();
        fnTagList();    
        searchSymptom();   
        
    }, [fnGetAnatomyList, fnTagList, searchSymptom]);


    const viewSymptom = (data) => {
        navigate("/viewSymptom", { state: data })
    }

    const deleteSymptom = async(data) => {
        window.swal({
             title: "Are you sure?",
             text: "Your will not be able to recover the data!",
             type: "warning",
             showCancelButton: true,
             confirmButtonClass: "btn-danger",
             confirmButtonText: "Yes, delete it!",
             closeOnConfirm: false,
             showLoaderOnConfirm: true
         },
            async function () {              
                let sysBusinessObj = new symptomBusiness()
                 let response = await sysBusinessObj.deleteSymptom(data.symptomId)
 
                 if (response && response.success) {
                     window.swal("Deleted!", "Symptom has been deleted.", "success");
                 }else{
                     window.swal("Cancelled", "Something went wrong!", "error");
                 }
                 
             });
     }

     const editSymptom = async (data) => {
        navigate("/symptom", { state: data })
    }

    


    return (
        <React.Fragment>
            <div>
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="page-header-title">
                                <div className="d-inline">
                                    <h4>Symptom</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="float-right">
                                <button type="button" className="btn btn-primary" onClick={() => { navigate("/symptom") }} ><i className="icofont icofont-plus m-r-5" />Add Symptom</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="page-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">                                 
                                    <h5><i className="feather icon-search"></i> Searh</h5>
                                </div>
                                <div className="card-block">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="col-form-label">
                                                            Title
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="text" className='form-control' 
                                                        value={title}
                                                        onChange={e => {
                                                            setTitle(e.target.value);

                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="col-form-label float-right">
                                                            Anatomy
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <select className='js-example-basic-multiple form-control select2-hidden-accessible'
                                                        id="anatomySelect"                                                        
                                                         multiple="multiple"
                                                         >
                                                            
                                                            {
                                                                
                                                                anatomyList.map(x => <option key={x.anatomyKey} value={x.anatomyKey}>{x.name}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="col-form-label">
                                                            Tags
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                    <select className='js-example-basic-multiple form-control select2-hidden-accessible' 
                                                    id="tagSelect"                                                   
                                                    multiple
                                                    >
                                                           
                                                            {
                                                                tagsList.map(x => <option key={x.tagId} value={x.tagId}>{x.name}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                      
                                    </div>

                                    <div className="row">
                                        <div className="col-md-5"></div>
                                        <div className="col-md-4">
                                            <button type="button" className="btn btn-primary" onClick={fnSearchSymptomCall} ><i className="feather icon-search" />Search</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <DataTable
                                            id="tblSymptom"
                                            data={symptomList}
                                            columns={[
                                                { title: "Title", type: "text", name: "name" },
                                                {
                                                    title: "Actions", type: "buttons", content: [
                                                        { id: "btnSymptomView", cssClass: "btn", iconClass: "icofont icofont-open-eye", callBack: viewSymptom },                                                        
                                                        { id: "btnSymptomEdit", cssClass: "btn", iconClass: "icofont icofont-ui-edit", callBack: editSymptom },
                                                        { id: "btnSymptomDelete", cssClass: "btn", iconClass: "icofont icofont-delete-alt", callBack: deleteSymptom }
                                                    ]
                                                }
                                            ]}

                                        />
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {/* {
                showViewSymptom && <ViewSymptom data={viewSymptomData} closePopup={hideViewSymptom}></ViewSymptom>
            } */}


        </React.Fragment>
    );
}


export default SymptomList;