import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { loggedOut } from '../../Redux/actions/loginAction';

const Header = (props) => {
  const navigate = useNavigate();

  const logOutUser=(e)=>{
    e.preventDefault();
    props.loggedOut();
    navigate("/login");
  }

  return (
    <nav className="navbar header-navbar pcoded-header">
      <div className="navbar-wrapper">
        <div className="navbar-logo">
          <a className="mobile-menu" id="mobile-collapse" href="#!">
            <i className="feather icon-menu" />
          </a>
          <a href="index.html">
            <img className="img-fluid" src={process.env.PUBLIC_URL + "/files/assets/images/logo.png"} alt="Theme-Logo" />
          </a>
          <button className="mobile-options" href="#">
            <i className="feather icon-more-horizontal" />
          </button>
        </div>
        <div className="navbar-container">

          <ul className="nav-right">

            <li className="user-profile header-notification">
              <div className="dropdown-primary dropdown">
                <div className="dropdown-toggle" data-bs-toggle="dropdown">
                  <img src={process.env.PUBLIC_URL + "/files/assets/images/avatar-4.jpg"} className="img-radius" alt="User-Profile" />
                  <span>{ props.currentUser.loginName  }</span>
                  <i className="feather icon-chevron-down" />
                </div>
                <ul className="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                 
                  <li>
                    <a href="user-profile.html">
                      <i className="feather icon-user" /> Profile
                    </a>
                  </li>                  
                  <li>
                    <Link to="" onClick={(e)=>logOutUser(e)}>
                      <i className="feather icon-log-out" /> Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  )
}


const mapStateToProps = state => {
  return {
      currentUser: state.loginReducer.currentUser.payload,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      loggedOut: (payload) => dispatch(loggedOut(payload)),  
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Header);