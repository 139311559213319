import React, { Fragment } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loggedInUser } from '../../Redux/actions/loginAction';
import LoginValidationSchemaconst from './loginValidation';
import loginBusiness from './loginBusiness';


const Login = (props) => {
    const navigate = useNavigate();
    return (
        <div>
            <Formik
                initialValues={{
                    loginname: "",
                    password: "",
                    loginFailed:false
                }}
                validationSchema={LoginValidationSchemaconst}
                onSubmit={async (values, onSubmitProps) => {

                    let validateUser = new loginBusiness();

                    let response = await validateUser.validate(values);

                    if (response && response.success) {
                        debugger;
                        props.loggedInUser(response.value);
                        onSubmitProps.setSubmitting(false);
                        navigate("/symptomList");
                        
                    }else{
                        onSubmitProps.setSubmitting(false);
                        onSubmitProps.resetForm();
                        onSubmitProps.setFieldValue("loginFailed", true);                      
                        
                    }



                }}
            >
                {formik => {

                    return (
                        <section className="login-block" style={{ background: 'none' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Form className="md-float-material form-material">
                                            <div className="text-center typography">
                                                {/* <img src={process.env.PUBLIC_URL + "/files/assets/images/logo.png"} alt="logo.png" /> */}
                                                <h1><small>LAKSHAN</small></h1>
                                            </div>
                                            <div className="auth-box card">
                                                <div className="card-block">
                                                    <div className="row m-b-20">
                                                        <div className="col-md-12">
                                                            <h3 className="text-center">Sign In</h3>
                                                            {formik.values.loginFailed && <h5 className="text-center text-danger">Invalid Username and password.</h5>}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="mb-3 form-primary">
                                                        <Field type="text" name="loginname" className={'form-control' + (formik.errors.loginname && formik.touched.loginname ? ' is-invalid' : '')} placeholder="User Name"
                                                            onChange={e => {
                                                                formik.setFieldValue("loginname", e.target.value);

                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        <span className="form-bar" />
                                                        <ErrorMessage name="loginname" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="mb-3 form-primary">
                                                        <Field type="password" name="password" className={'form-control' + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')} placeholder="Password"
                                                            onChange={e => {
                                                                formik.setFieldValue("password", e.target.value);

                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        <span className="form-bar" />
                                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="row m-t-25 text-start">
                                                        <div className="col-12">
                                                            <div className="checkbox-fade fade-in-primary d-">
                                                                <label className="form-label">
                                                                    <input type="checkbox" name="rememberme" defaultValue
                                                                    />
                                                                    <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary" /></span>
                                                                    <span className="text-inverse">Remember me</span>
                                                                </label>
                                                            </div>
                                                            <div className="forgot-phone text-end f-right">
                                                                <a href="auth-reset-password.html" className="text-end f-w-600"> Forgot
                                                                    Password?</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row m-t-30">
                                                        <div className="col-md-12">
                                                            <div className="d-grid">
                                                                <button type="submit"
                                                                    disabled={formik.isSubmitting}
                                                                    className="btn btn-primary btn-md btn-block waves-effect waves-light text-center">
                                                                    {formik.isSubmitting && <Fragment> <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>   Validating... </Fragment>}
                                                                    {!formik.isSubmitting && 'Sign in'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }}

            </Formik>
        </div>

    )
}


const mapDispatchToProps = dispatch => {
    return {
        loggedInUser: (payload) => dispatch(loggedInUser(payload)),  
     
    }
  }

  export default connect(null,mapDispatchToProps)(Login)






