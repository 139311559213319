import * as actions from '../actionTypes';


const INITIAL_STATE = {};


export default function loginReducer(state = INITIAL_STATE, action) {   
    switch (action.type) {
        case actions.LOGGED_IN_USER:
            return {
                ...state,
                currentUser: action.payload
            };
      
        default:
            return state;
    }
}