const getAPICall = async (URL) => {
    let responseData = []
    let apiResponse = await fetch(URL, {
        "method": "GET",
        "headers": {
            "accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Origin,Access-Control-Allow-Headers, Access-Control-Allow-Methods,Access-Control-Request-Method, Access-Control-Request-Headers,Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Credentials": true
        },
    });

    responseData = await apiResponse.json();

    return responseData;


}


const deleteAPICall = async (URL) => {
    debugger;
    let responseData = []
    let apiResponse = await fetch(URL, {
        "method": "DELETE",
        "headers": {
            "accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Origin,Access-Control-Allow-Headers, Access-Control-Allow-Methods,Access-Control-Request-Method, Access-Control-Request-Headers,Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Credentials": true
        },
    });

    responseData = await apiResponse.json();

    return responseData;


}

const postAPICall = async (URL, Parameters) => {   

    let fetchOptions = {
        "method": "POST",
        "headers": {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Origin,Access-Control-Allow-Headers, Access-Control-Allow-Methods,Access-Control-Request-Method, Access-Control-Request-Headers,Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Credentials": true
        }
    }

    if (Parameters) {
        fetchOptions.body = JSON.stringify(Parameters);
    }


   return await fetch(URL, fetchOptions)
        .then(response => response.json())
        .then(response => {
            return response;
        })
        .catch(err => {
            console.log(err);
        });
}


export {
    getAPICall,
    postAPICall,
    deleteAPICall
    
}