import React from 'react';
import PublicRoute from './Component/Routes/publicRoute';
import RequireAuth from './Component/Routes/privateRoute';
import { connect } from "react-redux";


import Login from './Containers/Login/login';
import MainLayout from './Layout/mainLayout';
import SymptomList from './Containers/Masters/Symptom/list';
import Symptom from './Containers/Masters/Symptom/symptom';
import ViewSymptom from './Containers/Masters/Symptom/viewSymptom';
import TagList from './Containers/Masters/Tags/tagList'
import Tags from './Containers/Masters/Tags/tags'


import {
  BrowserRouter as Router,  
  Routes,
  Route
} from 'react-router-dom';

const Main = (props) => {
   
    // const isAuthenticated = props.currentUser && props.currentUser.authStatus && props.currentUser.authStatus==="SUCCESS" ? true : false;
  
    return (
        
        <Router>
            <Routes>

                <Route
                    path="/login"
                    element={<PublicRoute />}
                >
                    <Route path="/login" element={<Login />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route
                        path="/"
                        element={
                            <MainLayout />
                        }
                    >
                        {/* <Route element={<RequireAuth />}> */}

                        <Route path="/symptomList" element={<SymptomList />} />
                        <Route path="/symptom" element={<Symptom />} />
                        <Route path="/viewSymptom" element={<ViewSymptom />} />

                        <Route path="/tagList" element={<TagList />} />
                        <Route path="/tags" element={<Tags />} />

                        {/* </Route> */}
                    </Route>
                </Route>
                    
                    
                
               

            </Routes>

        </Router>
    );
  };
  


  const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
    }
  }

  export default connect(mapStateToProps, null)(Main);