import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from '../../../Component/Datatable/Datatable'
import symptomBusiness from "../Symptom/symptomBusiness";
import tagsBusiness from "./tagsBusiness";



const TagList = () => {
    const navigate = useNavigate();

    const [tagList, getTags] = useState([]);
    const [name, setName] = useState("");

    const fnTagList = useCallback(async () => {
       
        let sysBusinessObj = new symptomBusiness()
        let param = {
            "name": name
        }
        let list = await sysBusinessObj.getTagList(param);

        getTags(list);
    },[]);

    useEffect(() => {
        fnTagList();
    }, [fnTagList])

    const deleteTag = async(data) => {
       window.swal({
            title: "Are you sure?",
            text: "Your will not be able to recover the data!",
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: false,
            showLoaderOnConfirm: true
        },
           async function () {              
                let tagsBusinessObj = new tagsBusiness();
                let response = await tagsBusinessObj.deleteTag(data.tagId)

                if (response && response.success) {
                    window.swal("Deleted!", "Tag has been deleted.", "success");
                }else{
                    window.swal("Cancelled", "Something went wrong!", "error");
                }
                
            });
    }


    const editTag = async (data) => {
        navigate("/tags", { state: data })
    }

    return (
        <React.Fragment>
            <div>
                <div className="page-header">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="page-header-title">
                                <div className="d-inline">
                                    <h4>Tags</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="float-right">
                                <button type="button" className="btn btn-primary" onClick={() => { navigate("/tags") }} ><i className="icofont icofont-plus m-r-5" />Add Tag</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="page-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">                                 
                                    <h5><i className="feather icon-search"></i> Searh</h5>
                                </div>
                                <div className="card-block">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label className="col-form-label">
                                                            Name
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="text" className='form-control' 
                                                        value={name}
                                                        onChange={e => {
                                                           console.log(e.target.value);
                                                           setName(e.target.value);

                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>

                                 

                                    <div className="row">
                                        <div className="col-md-5"></div>
                                        <div className="col-md-4">
                                            <button type="button" className="btn btn-primary" onClick={fnTagList} ><i className="feather icon-search" />Search</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <DataTable
                                            id="tblSymptom"
                                            data={tagList}
                                            columns={[
                                                { title: "Name", type: "text", name: "name" },
                                                {
                                                    title: "Actions", type: "buttons", content: [                                                                                                               
                                                        { id: "btnTagEdit", cssClass: "btn", iconClass: "icofont icofont-ui-edit", callBack: editTag },
                                                        { id: "btnTagDelete", cssClass: "btn", iconClass: "icofont icofont-delete-alt", callBack: deleteTag }
                                                    ]
                                                }
                                            ]}

                                        />
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>




        </React.Fragment>
    );
}


export default TagList;