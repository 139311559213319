import React from 'react';
import {
    
    Link,
   
} from 'react-router-dom';

const SideBar = (props) => {
    return (
        <nav className="pcoded-navbar">
            
            <div className="pcoded-inner-navbar main-menu">
                
                <ul className="pcoded-item pcoded-left-item">
                    <li className="pcoded-hasmenu active pcoded-trigger">
                        <Link to="#">
                            <span className="pcoded-micon"><i className="feather icon-home" /></span>
                            <span className="pcoded-mtext">Masters</span>
                        </Link>
                        <ul className="pcoded-submenu">
                            <li className="">
                                <Link to="/symptomList">
                                    <span className="pcoded-mtext">Symptom</span>
                                </Link>

                            </li>
                            <li className="">
                                <Link to="/tagList">
                                    <span className="pcoded-mtext">Tags</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                  
                </ul>
            </div>
            
        </nav>



    )
}

export default SideBar;