import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { connect } from "react-redux";


const RequireAuth = (props) => {  
  const isAuthenticated = props.currentUser && props.currentUser.payload && props.currentUser.payload.authStatus && props.currentUser.payload.authStatus==="SUCCESS" ? true : false;
    let location = useLocation();
    if (!isAuthenticated) {
       
        return <Navigate to="/login" state={{ from: location }} />;
      }
    
      return <Outlet />;
};

const mapStateToProps = state => {
  return {
      currentUser: state.loginReducer.currentUser,
  }
}

export default connect(mapStateToProps, null) (RequireAuth);