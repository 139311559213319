require('dotenv').config();

const API_URL = process.env.REACT_APP_API_URL;

const identity = {
    login: API_URL + "/v1/identity/login"
}

const anatomy={
    anatomyList: API_URL + "/v1/anatomy"
}

const symptom={
    symptomList: API_URL + "/v1/symptoms/search",
    saveSymptom: API_URL + "/v1/symptoms",
    getSymptom: API_URL + "/v1/symptoms/{0}",
    deleteSymptom: API_URL + "/v1/symptoms/{0}"
}

const tags={
    tagsList: API_URL + "/v1/tags",
    savetag: API_URL + "/v1/tags",
    deleteTag: API_URL + "/v1/tags/{0}"
}

export {
    identity,
    anatomy,
    symptom,
    tags
}