import { postAPICall } from '../../utils/apiCallFunctions';
import { identity } from '../../utils/apiURLs';


class loginBusiness {
    
    validate = async (data) => {      
        const reponse = await postAPICall(identity.login, data);
        return reponse
    }    

}

export default loginBusiness;