import React, {  Fragment, useEffect } from "react";


const DataTable = (props) => {


    useEffect(() => {       
        initilizeTable();
    });

  


  const  initilizeTable = () => {

        try {

           
            let columnsInfo = getColumnsInfo(props.columns);

            if (window.$.fn.dataTable.isDataTable(`#${props.id}`)) {
                window.$(`#${props.id}`).dataTable().fnDestroy()
            }

            window.$(`#${props.id}`).DataTable({
                data: props.data,
                lengthChange: true,
                searching: false,
                ordering: true,
                info: true,
                autoWidth: false,
                columns: columnsInfo
            });

            registerEvent();

        }
        catch (err) {
            console.log(`Error in datatable>>initilizeTable function ${err}`);
        }

    }


  const  registerEvent = () => {
     
        let buttons = props.columns.filter(b => b.type.toLowerCase() === "buttons");

        if (buttons.length > 0) {
            window.$(`#${props.id} tbody`).unbind('click');
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].content && buttons[i].content.length > 0) {
                    for (let con = 0; con < buttons[i].content.length; con++) {
                        let button = buttons[i].content[con];
                        if (button.callBack && button.callBack instanceof Function) {
                            window.$(`#${props.id} tbody`).on('click', `#${button.id}`, function (e) {
                                e.preventDefault();
                                let data = window.$(`#${props.id}`).DataTable().row(window.$(this).parents('tr')).data();
                                button.callBack(data);
                            });

                        }
                    }

                }

            }
        }
    }

    return (
        <Fragment>
            <div className="dt-responsive table-responsive">
                <table id={props.id} className="table table-striped table-bordered nowrap">
                    <TableHeader columns={props.columns} />
                    <tbody>

                    </tbody>
                </table>

            </div>
        </Fragment>
    )
}


const TableHeader = (props) => {
    return (
        <React.Fragment>
            <thead>
                <tr>
                    {
                        props.columns && props.columns.length > 0 ?
                            props.columns.map(element => <th key={element.title}>{element.title}</th>)
                            : <th>No headers defiend</th>
                    }

                </tr>
            </thead>
        </React.Fragment>
    )
}


const getColumnsInfo = (columns) => {
 
    let columnInfo = [];

    for (let i = 0; i < columns.length; i++) {
        let column = columns[i];

        switch (column.type.toLowerCase()) {
            case "text":
                columnInfo.push({ data: column.name })
                break;
            case "buttons":
                let defaultContent = `<div class="button-group">`;
                if (column.content && column.content.length > 0) {
                    for (let con = 0; con < column.content.length; con++) {
                        let button = column.content[con];

                        defaultContent += `
                            <a 
                            id="${button.id ? button.id : ""}" 
                            class="${button.cssClass ? button.cssClass : ""}">
                            <i class="${button.iconClass ? button.iconClass : ""}"></i>
                            </a>
                            `

                    }

                }

                columnInfo.push({
                    data: null,
                    defaultContent: defaultContent + `</div>`
                });

                break;
            default:
                break;

        }
    }
    return columnInfo;

}


export default DataTable;