import { createStore } from 'redux';
import rootReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const persistConfig = {
  key: 'root',
  storage: storage  
};
const pReducer = persistReducer(persistConfig, rootReducer);


const store = createStore(pReducer);
const persistor = persistStore(store);

export { persistor, store };




