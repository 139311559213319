import { getAPICall, postAPICall } from '../../../utils/apiCallFunctions';
import { anatomy, symptom, tags } from '../../../utils/apiURLs';


class symptomBusiness {
    
    getAnatomyList = async (param) => {     
       
        const response = await getAPICall(`${anatomy.anatomyList}?key=${param.key}&name=${param.name}`);
       
        if(response && response.success){
            return response.value;
        }

        return [];
        
    }
    
    getSymptomList = async (param) => {
        const response = await postAPICall(symptom.symptomList, param);
        if (response && response.success) {
            return response.value;
        }

        return [];

    } 

    getTagList = async (param) => {   
       
        const response = await getAPICall(`${tags.tagsList}?name=${param.name}`);
        if(response && response.success){
            return response.value;
        }

        return [];
        
    }

    saveSymptom = async (param) => {
        const response = await postAPICall(symptom.saveSymptom, param);
        return response;

    } 

    deleteSymptom = async (param) => {
        const response = await postAPICall(symptom.deleteSymptom, param);
        return response;

    } 

    getSymptom = async (symptomId) => {     
       
        const response = await getAPICall(`${symptom.getSymptom.replace("{0}",symptomId)}`);
       
        if(response && response.success){
            return response.value;
        }

        return {};
        
    }

}

export default symptomBusiness;