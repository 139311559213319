import { Form, Field, Formik, ErrorMessage } from "formik";
import React, { Fragment, useEffect } from "react";
import {  useNavigate , useLocation} from 'react-router-dom';
import CKEditor from '../../../Component/Editor/CKEditor'
import SymptomValidationSchemaconst from "./symptomValidations";
import symptomBusiness from "./symptomBusiness";



const Symptom = React.memo(props => {

    const navigate = useNavigate();
    const location = useLocation();
   

    let initialValues = {
        symptomId: location.state ? location.state.symptomId : 0,
        symptomtitle: location.state ? location.state.name : "",
        shortDesc: location.state ? location.state.shortDescription : "",
        detDesc: location.state ? location.state.detailDescription : "",
        showSuccess: false,
        showError: false

    }

    useEffect(() => {
        window.$('.js-example-basic-multiple').select2();
    }, [])



    return (
        <Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={SymptomValidationSchemaconst}
                onSubmit={async (values, onSubmitProps) => {
                    let symptBusinessObj = new symptomBusiness();

                    let param = {
                        "symptomId": values.symptomId,
                        "name": values.symptomtitle,
                        "shortDescription": values.shortDesc,
                        "detailDescription": JSON.stringify(values.detDesc),
                        "isActive": 1,
                        "loginUserId": 0
                    }

                    let response = await symptBusinessObj.saveSymptom(param);

                    if (response && response.success) {
                        onSubmitProps.setSubmitting(false);
                        onSubmitProps.resetForm();

                        onSubmitProps.setFieldValue("shortDesc", "");
                        onSubmitProps.setFieldValue("detDesc", "");

                        onSubmitProps.setFieldValue("showSuccess", true);

                        setTimeout(() => {
                            onSubmitProps.setFieldValue("showSuccess", false);
                        }, 3000);
                    } else {

                    }


                }}


            >


                {(formik) => {


                    return (

                        <Form className="md-float-material form-material"> 
                            <div className="page-header">
                                <div className="row align-items-end">
                                    <div className="col-lg-6">
                                        <div className="page-header-title">
                                            <div className="d-inline">
                                                <h4>Add/Update Symptom</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ">
                                        <div className="float-right">
                                        <button type="button" className="btn btn-inverse m-r-10" onClick={() => { navigate("/symptomList") }} ><i className="icofont icofont-arrow-left m-r-5" />Back to Symptom List</button>
                                            <button type="reset" className="btn btn-info m-r-10" ><i className="icofont icofont-close m-r-5" />Cancel</button>
                                            <button type="submit"
                                                disabled={formik.isSubmitting}
                                                className="btn btn-primary">
                                                   
                                                {formik.isSubmitting && <Fragment> <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>   Please wait... </Fragment>}
                                                {!formik.isSubmitting && <Fragment><i className="icofont icofont-save"></i> Save</Fragment> }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="page-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5>Symptom Info</h5>
                                                <div className="card-header-right">
                                                    <ul className="list-unstyled card-option">
                                                        <li><i className="feather minimize-card icon-minus" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="card-block">
                                                {
                                                    formik.values.showSuccess &&
                                                    <div className="alert alert-dismissible alert-success">
                                                        
                                                        <strong>Symptom Saved successfully!</strong>
                                                    </div>

                                                }
                                                
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="col-form-label">
                                                                    Title <span className="text-danger">*</span>
                                                                </label>
                                                                <Field type="text" className={'form-control' + (formik.errors.symptomtitle && formik.touched.symptomtitle ? ' is-invalid' : '')}
                                                                    name="symptomtitle"
                                                                    maxLength={100}
                                                                    autoComplete="off"
                                                                    onChange={e => {
                                                                        formik.setFieldValue("symptomtitle", e.target.value);

                                                                    }} />
                                                                <ErrorMessage name="symptomtitle" component="div" className="invalid-feedback" />

                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">

                                                                <label className="col-form-label">
                                                                    Short Description <span className="text-danger">*</span>
                                                                </label>

                                                                <textarea className={'form-control' + (formik.errors.shortDesc && formik.touched.shortDesc ? ' is-invalid' : '')}
                                                                    name="shortDesc"
                                                                    maxLength={500}
                                                                    rows={5}
                                                                    value={formik.values.shortDesc}  
                                                                    onChange={e => {
                                                                        formik.setFieldValue("shortDesc", e.target.value);

                                                                    }} />
                                                                <ErrorMessage name="shortDesc" component="div" className="invalid-feedback" />



                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="col-form-label">
                                                                    Details Description 
                                                                </label>
                                                                <ErrorMessage name="detDesc" component="div" className="invalid-feedback" />
                                                                <CKEditor name="detDesc" id="detailEditor" value=""
                                                                    className={'form-control' + (formik.errors.detDesc && formik.touched.detDesc ? ' is-invalid' : '')}
                                                                    height={500}
                                                                    value={formik.values.detDesc} 
                                                                    onChange={data => {
                                                                        formik.setFieldValue("detDesc", data);

                                                                    }}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Form>



                    )


                }}


            </Formik>
        </Fragment>
    )
})

export default Symptom;